import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Button, Col, H2, Loading, Row, Text, Wrapper, SizerWrapper } from '@commonsku/styles';
import { fetchClientProjects, fetchClientProjectsFiles, setLoading } from '../../store/configureClientPortalStore';
import { order_types_status } from './utils';
import DebouncedSearchBox from '../DebouncedSearchBox';
import { dateStr, getIdentityUtils, getImageSrc, } from '../../utils';
import Img from '../Img';
import useClientContactLogin from '../../hooks/useClientContactLogin';
import useOnScreen from '../../hooks/useOnScreen';
import useWindowSize from '../../hooks/useWindowSize';

const today = new Date();
const tomorrow = new Date(today.getFullYear(), today.getMonth(), today.getDate()+1, 0, 0, 0);
const startDate = new Date(today.getFullYear()-8, 0, 1, 0, 0, 0);

function Projects({ client, base_path }) {
    const navigate = useNavigate();

    const pageRef = useRef();
    const projectsListRootRef = useRef();
    const dispatch = useDispatch();
    const identity = useSelector(s => s.identity);
    const contactInStore = useSelector(s => s.client_portal.entities.client_contact);
    const loading = useSelector(s => s.client_portal.display.loading.projects);
    const all_projects = useSelector(s => _.values(s.client_portal.entities.projects));
    const [filters, setFilters] = useState({
        search_query: '',
        current_projects: -1, // -1, 0, 1
        start_date: startDate,
        end_date: tomorrow,
    });
    const windowSize = useWindowSize();
    const updateFilters = (data={}) => {
        dispatch(setLoading({key: 'projects', value: true}));
        setFilters(s => ({...s, ...data}));
        dispatch(setLoading({key: 'projects', value: false}));
    };

    const { isAdmin, hasCapabilities } = getIdentityUtils(identity);
    useClientContactLogin(client);

    const canFetchProjects = !_.isEmpty(client) && (
        !_.isEmpty(contactInStore) || (identity && (
            identity.user_id === client.sales_rep_id ||
            isAdmin() || hasCapabilities(['MODIFY-CLIENT-REP'])
        ))
    );

    const fetchProjects = useCallback((params={}) => {
        return Promise.resolve(
            dispatch(fetchClientProjects(client.client_id, '', {
                contact_id: _.get(contactInStore, 'contact_id', ''),
                email: _.get(contactInStore, 'contact_email', ''),
                restrict_to_user_projects: _.get(client, 'portal.restrict_to_user_projects', 0),
                check_total: 1,
                start_date: dateStr(startDate),
                // end_date: dateStr(tomorrow),
                ...params,
            }))
        ).then(res => {
            if (res?.error && res?.redirectTo) {
                navigate(res.redirectTo);
            }
        });
    }, [dispatch, client, contactInStore, navigate]);

    useEffect(() => {
        if (canFetchProjects) {
            fetchProjects();
        }
    }, [canFetchProjects, fetchProjects]);

    const winHeight = windowSize[1];
    useEffect(() => {
        const elem = _.get(projectsListRootRef, 'current.children[0]', null);
        if (elem && elem.style) {
            elem.style.height = (!winHeight || winHeight < 600 ? 600 : winHeight)-(124 + 112 + 20) + "px";
        }
    }, [canFetchProjects, fetchProjects, winHeight]);

    const projects = useMemo(() => {
        if (!filters.search_query && filters.current_projects === -1) {
            return all_projects;
        }
        return _.filter(all_projects, (v) => {
            let ret = true;
            if (filters.search_query) {
                ret = ret && ((v.order_type || "").toLowerCase().includes(filters.search_query.toLowerCase()) ||
                    (v.job_name || "").toLowerCase().includes(filters.search_query.toLowerCase()) ||
                    (v.job_number ?? '').toString().includes(filters.search_query.toLowerCase()));
            }
            if (filters.current_projects !== -1) {
                if (+filters.current_projects === 1) {
                    ret = ret && +v.is_open === 1;
                } else if (+filters.current_projects === 0) {
                    ret = ret && +v.is_open !== 1;
                }
            }

            return ret;
        });
    }, [all_projects, filters]);

    function renderFilters() {
        return null;
    }

    function renderProjectRows() {
        return projects && projects.length > 0 ? <>
            {projects.map((k, i) => {
                const v = projects[k];
                return (
                    <ScreenedProjectRow key={`project_${v.job_id}-${i}`} index={i} project={v} />
                );
            })}
            </>: null;
    }

    function renderWindowedProjectRows() {
        return (
            <AutoSizer>
                {({ height, width, ...rest }) => {
                    const winWidth = windowSize[0];
                    let itemSize = 651;
                    if (winWidth >= 1191) {
                        itemSize = 131;
                    } else if (winWidth >= 768) {
                        itemSize = 228;
                    } else if (winWidth >= 538) {
                        itemSize = 285;
                    } else if (winWidth >= 345) {
                        itemSize = 382;
                    } else if (winWidth >= 368) {
                        itemSize = 479;
                    }

                    return (
                        <List className="projects-list"
                            height={height}
                            itemCount={projects.length}
                            itemSize={itemSize}
                            width={width}
                            itemData={projects}
                            useIsScrolling
                        >{ProjectWindowRow}</List>
                    );
                }}
            </AutoSizer>
        );
    }

    return (
        <Wrapper bg="#fff" style={{padding: 20}} className="projects">
            <Row padded className="project-filters">
                <Col padded xs sm={3} md={3} style={{alignSelf: 'center'}}>
                    <H2 text className="page-title">Projects</H2>
                </Col>
                <Col padded xs sm={5} md={6} style={{alignSelf: 'center'}}>
                    {renderFilters()}
                </Col>
                <Col padded xs sm={4} md={3} style={{alignSelf: 'center'}} smStyle="padding-top: 32px;">
                    <DebouncedSearchBox
                        name="search_query"
                        handleSearch={(val) => {
                            updateFilters({search_query: val});
                        }}
                        debounceInterval={600}
                        style={{borderRadius: '20px',}} placeholder="Search..."
                    />
                </Col>
            </Row>
            <Row padded ref={pageRef}>
                <Col xs>
                    {loading ? <Loading /> : null}
                </Col>
                <Col xs className="projects-list-root" ref={projectsListRootRef}>
                    {renderWindowedProjectRows()}
                </Col>
            </Row>
        </Wrapper>
    );
}

function ProjectWindowRow({ index, style, data, isScrolling, ...rest }) {
    const dispatch = useDispatch();
    const project = data[index];

    useEffect(() => {
        if(project.files === false && !isScrolling) {
            dispatch(fetchClientProjectsFiles(project.account_id, {job_id: project.job_id, order_id: project.order_id, }));
        }
    }, [project, isScrolling]);

    return (
        <ProjectRow project={project} index={index} style={style} />
    );
}

function ScreenedProjectRow(props) {
    const ref = useRef();
    const onScreen = useOnScreen(ref, {
        rootMargin: "300px",
        thresholds: 0.01,
    });
    const style = onScreen ? {
        opacity: 1,
        transition: 'opacity 500ms ease-in',
    } : { opacity: 0.01 };

    return (
        <div ref={ref} style={style}>
            {onScreen ? <ProjectRow {...props} style={style} /> :
                <SizerWrapper xlStyle="height: 117px;" lgStyle="height: 198px;" smStyle="height: 271px;" xsStyle="height: 353px;">...</SizerWrapper>}
        </div>
    );
}

function ProjectRow({ project, index, style={}, loaded=true, }) {
    const identity = useSelector(s => s.identity);
    const contactInStore = useSelector(s => s.client_portal.entities.client_contact);
    const base_url = useSelector(s => s.temp.base_url || '');
    const order_status = (project && project.status_name) || '';
    const job_status = _.get(order_types_status, `${project && project.order_type}.${order_status}`, {value: '', img_src: ''});

    const contact_email = (contactInStore && contactInStore.contact_email) || '';
    const isTitleLink = identity && identity.user_id;
    const baseUrl = base_url.endsWith('/') ? base_url.slice(0, base_url.length-1) : base_url;

    if (!project) {
        return null;
    }

    return (
        <Row padded
            className="project-row"
            id={`client_project-${index}`}
            style={style}
        >
            <Col padded xs md={3} style={{alignSelf: 'center', display: 'flex'}}>
                {loaded && job_status.img_src ? <img style={{maxHeight: '40px', paddingRight: '5px'}} src={job_status.img_src} alt={job_status.value} /> : null}
                <span style={{display: 'inline-block'}}>
                    <Text as={isTitleLink ? "a" : "span"}
                        className="project-title"
                        href={`${baseUrl}/project/${project.job_number}`}
                        target="_blank"
                    >{project.job_name} (#{project.job_number})</Text>
                    <Text style={{display: 'block'}}>{job_status.value}</Text>
                </span>
            </Col>
            <Col padded xs md={5} style={{alignSelf: 'center'}} className="pictures-wrapper">
                {project.files ? project.files.map((f,i) => {
                    return (<Img
                                key={`file-${f.file_id}-${i}`}
                                src={loaded && getImageSrc(f, 'small')}
                                alt={f.file_display_name || f.file_name}
                                style={{ maxWidth: 80, maxHeight: 80, padding: 5, }}
                    />);
                }) : (project.files === false ? <Loading /> : '')}
            </Col>
            <Col padded xs md={2} style={{alignSelf: 'center', textAlign: 'center'}}>{project.order_date}</Col>
            <Col xs md={2} style={{alignSelf: 'center', textAlign: 'right'}} className="buttons-wrapper" primary={project.is_open == '1'}>
                <Button className={`reorder_btn-${project.is_open == '1' ? 'open' : 'closed'}`} style={{
                    width: 150,
                }} onClick={e => {
                    window.open(
                        `${baseUrl}/portal/${project.job_id}${project.is_open == '0' ? ("?reorder=y&email="+contact_email) : ""}`,
                        '_blank'
                    );
                }}>
                    {project.is_open == '1' ? 'View Project' : 'View/Reorder'}
                </Button>
            </Col>
        </Row>
    );
}

export default Projects;
