import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary, Page, SizerWrapper, Theme } from '@commonsku/styles';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import createPopup from '../../../popup-factory';
import Topbar from './Topbar';
import { themeOptions } from '../utils';
import { clearToasts } from '../../../store/configureClientPortalStore';
import { createGlobalStyle } from '../../helpers';
import useWindowSize from '../../../hooks/useWindowSize';

export const GlobalStyle = createGlobalStyle(p => `
    body {
        font-family: 'skufont-demibold', "museo-sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        background: ${p.bodyBg || 'initial'};
    }

    img.left-img {
        display: inline-block;
        vertical-align: middle;
        max-width: 100%;
    }

    @media only screen and (min-width: 0px) {
        .projects .project-row .reorder_btn {
            margin: 10px 10px;
        }

        .projects .project-row .pictures-wrapper {
            text-align: center;
        }

        .start-project .react-datepicker__input-container, .start-project .react-datepicker-wrapper {
            width: 100%;
        }

        .top-nav {
            padding-bottom: 25px !important;
        }
    }
    @media only screen and (min-width: 768px) {
        .projects .project-row .reorder_btn {
            margin: initial;
        }

        .projects .project-row .pictures-wrapper {
            text-align: initial;
        }

        .top-nav {
            padding-bottom: initial !important;
        }
    }

    @media only screen and (max-width: 768px) and (min-width: 0px) {
        .top-nav .nav-right .nav-items-container {
            float: none !important;
            display: inline-flex !important;
        }

        .top-nav .nav-left .title {
            float: none !important;
        }

        .projects .project-row .buttons-wrapper {
            text-align: center !important;
        }
    }

    @media only screen and (max-width: 1105px) and (min-width: 768px) {
        #start-project-card {
            -webkit-flex-basis: 92%;
            -ms-flex-preferred-size: 92%;
            flex-basis: 92%;
            max-width: 92%;
            display: initial;
            margin-left: 4%;
        }
    }

    .csku_styles div.Toastify__toast-body {
        margin: auto 0;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 6px;
    }

    .csku_styles div.Toastify__toast {
        margin-bottom: 1rem;
        padding: 8px;
    }


    .top-nav-wrapper {
        position: fixed;
        z-index: 10;
    }
    @media only screen and (max-width: 411px) and (min-width: 0px) {
        .top-nav-wrapper {
            position: relative;
        }
    }

    @media only screen and (max-width: 640px) {
        .filters .react-datepicker__input-container {
            width: 85vw;
        }
    }

    .page-title {
        margin: 10px 0px 0px 10px;
    }

    .project-row, .project-filters {
        border-bottom: 2px solid ${p.theme.colors.bggray};
    }

    .reorder_btn-closed {
        color: ${p.theme.colors.primary} !important;
        border-color: ${p.theme.colors.primary} !important;
        background: ${p.theme.colors.white} !important;
    }

    .project-row .project-title {
        font-size: 17;
        font-weight: bold;
        color: ${p.theme.colors.primary};
        text-decoration: none;
    }

    .limited-projects-text {
        color: ${p.theme.colors.cta} !important;
    }

    .projects-list-root {
        height: 100%;
        overflow-x: hidden;
    }
`);

const DEFAULT_COLOR = '#02c0da';

function Layout({ children, title, popups, base_path, styles = {}, body_bg = '', avatar = {}, ...props }) {
    const navRef = useRef(null);
    const size = useWindowSize(); // rerender on window size change to re-calc navRef's height

    const dispatch = useDispatch();
    const toasts = useSelector(s => s.client_portal.display.toasts || []);

    const portalData = useSelector(s => s.client_portal.entities.portal_data);
    const template_color = portalData?.theme_color ? portalData.theme_color : DEFAULT_COLOR;
    if (portalData?.portal_logo) {
        avatar = {
            small: portalData?.portal_logo
        };
    }

    useEffect(() => {
        if (toasts && toasts.length > 0) {
            toasts.forEach((t, i) => {
                switch (t.type) {
                    case 'success':
                        toast.success(t.message);
                        break;
                    case 'info':
                        toast.info(t.message);
                        break;
                    case 'warn':
                        toast.warn(t.message);
                        break;
                    case 'error':
                        toast.error(t.message);
                        break;
                    default:
                        break;
                }
            });
            dispatch(clearToasts());
        }
    }, [toasts]);

    const pageBodyContentStyle = {
        ...(size[0] > 412 && navRef && navRef.current ? {
            marginTop: navRef.current.offsetHeight + 15
        } : {}),
    };
    const theme = {
        ...themeOptions,
        colors: {
            ...themeOptions.colors,
            primary: template_color,
        }
    };

    return (
        <Theme theme={theme}>
            <GlobalStyle theme={{ fontFamily: "'skufont-regular', 'sans-serif', 'Roboto'", ...theme, }} bodyBg={body_bg} />
            <ErrorBoundary>
                <Page style={{ height: '100%', background: body_bg || 'initial' }} className="csku-styles">
                    <div
                        ref={navRef}
                        className="top-nav-wrapper"
                        style={{ display: 'block', boxShadow: 'rgb(0 0 0 / 7%) 0px 5px 5px', top: 0, width: '100%' }}
                    >
                        <Topbar title={title} base_path={base_path} avatar={avatar} />
                    </div>
                    <div id="page-body-content" style={{ height: '100%', ...pageBodyContentStyle, ...styles, }}>
                        {children}
                    </div>
                    <ToastContainer
                        autoClose={3000}
                        hideProgressBar={true}
                    />
                    {popups ? popups.map((p, idx) => createPopup(p, idx, props)) : null}
                </Page>
            </ErrorBoundary>
        </Theme>
    );
}

export default Layout;
