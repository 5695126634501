import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Wrapper, Col, Row, Box, H2, Text, LabeledTextarea, Button, Loading } from '@commonsku/styles';
import { themeOptions } from './utils';
import DateInput, { LabeledDateInput } from '../DateInput';
import { NumberInput } from '../helpers';
import RawHTML from '../helpers/RawHTML';
import { createProject, setDisplayEntity } from '../../store/configureClientPortalStore';
import useClientContactLogin from '../../hooks/useClientContactLogin';

function StartProject({ client, ...props }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loading = useSelector(s => s.client_portal.display.loading);
    const create_status = useSelector(s => s.client_portal.display.entities.create_project);
    const [state, setState] = useState({
        date_inhandsdate: '',
        total_budget: '',
        briefing: '',
    });
    const [errors, setErrors] = useState({});
    const hasErrors = Object.values(errors).filter(v => v).length > 0;

    useClientContactLogin(client);

    useEffect(() => {
        if (create_status && create_status.type == 'done') {
            setState({
                date_inhandsdate: '',
                total_budget: '',
                briefing: '',
            });
        }
    }, [create_status]);

    function onChange (field, value) {
        if (errors[field]) {
            if (field == 'briefing') {
                if (value && value.length > 3) {
                    errors[field] = false;
                }
            } else if (value) {
                errors[field] = false;
            }
        }
        setState(s => ({...s, [field]: value}));
    }

    function onContinue(e) {
        setErrors({});
        let shouldContinue = true;

        if (!state.briefing) {
            setErrors(s => ({...s, briefing: true,}));
            shouldContinue = false;
        }
        if (!state.date_inhandsdate) {
            setErrors(s => ({...s, date_inhandsdate: true,}));
            shouldContinue = false;
        }

        if (!shouldContinue) {
            return;
        }

        dispatch(createProject(client.client_id, state));
    }

    function onGoHome() {
        dispatch(setDisplayEntity({key: 'create_project', value: ''}));
        navigate(`/client/${client.client_id}/portal/`);
    }

    function renderLoading() {
        return (
            <Row>
                <Col xs><Loading /></Col>
                <Col xs style={{textAlign: 'center'}}>
                    <Text>Creating Project...</Text>
                </Col>
            </Row>
        );
    }

    function renderCreateProjectForm() {
        return (
            <>
                {hasErrors ? <Row>
                    <Col xs style={{textAlign: 'center'}}>
                        <Text style={{fontSize: '1.2rem', color: themeOptions.colors.cta}}>* Please fill in all the required fields.</Text>
                    </Col>
                </Row> : null}
                <Row padded>
                    <Col padded xs>
                        <LabeledTextarea
                            name="briefing"
                            label="Let us know some details of what you're looking for (*)"
                            value={state.briefing}
                            onChange={e => onChange('briefing', e.target.value)}
                            style={{ maxWidth: '100%', minWidth: '100%', minHeight: 50, height: 115 }}
                            error={errors.briefing}
                        />
                    </Col>
                    <Col padded xs md={6}>
                        <DateInput
                            customInput={<LabeledDateInput date={state.date_inhandsdate} label="When do you need it by? (*)" error={errors.date_inhandsdate} />}
                            showMonthDropdown
                            showYearDropdown
                            value={state.date_inhandsdate}
                            onChange={date => onChange('date_inhandsdate', date)}
                            portalId="root-portal"
                            popperPlacement="auto"
                            name="date_inhandsdate"
                            minDate={new Date()}
                        />
                    </Col>
                    <Col padded xs md={6}>
                        <NumberInput
                            label="What is your budget?"
                            onChange={e => onChange('total_budget', e ? e.value : '')}
                            localeOptions={{
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                currency: client.default_currency_id || 'CAD',
                                style: 'currency',
                                currencyDisplay: 'narrowSymbol',
                            }}
                            min={0}
                            name="total_budget"
                            error={errors.total_budget}
                        />
                    </Col>
                </Row>
                <Row padded>
                    <Col xs style={{textAlign: 'right'}}>
                        <Button disabled={hasErrors} onClick={onContinue}>Submit</Button>
                    </Col>
                </Row>
            </>
        );
    }

    function renderCreatedProject() {
        return (
            <>
                <Row padded>
                    <Col xs style={{textAlign: 'center'}}>
                        {_.get(client, 'portal.start_project_message', false)
                            ? <RawHTML>{_.get(client, 'portal.start_project_message')}</RawHTML>
                            : <>
                                <Text as="p">The project has been successfully created...</Text>
                                <Text as="p">Someone will get back to you soon</Text>
                            </>}
                    </Col>
                </Row>
                <Row padded>
                    <Col xs style={{textAlign: 'center'}}>
                        <Button onClick={onGoHome}>Go Home</Button>
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <Wrapper bg={themeOptions.colors.primary0} style={{padding: 20}} className="start-project">
            <Row>
                <Col xs sm={9} smOffset={1.5} md={8} mdOffset={2} xl={8} xlOffset={2} id="start-project-card">
                    <Box padded>
                        <Row>
                            <Col padded xs>
                                <H2 text style={{marginTop: 0}}>Start Project</H2>
                                <Text as="p">{client.contact_email || ''}</Text>
                            </Col>
                        </Row>
                        {loading.create_project
                            ? renderLoading()
                            : create_status == 'done'
                            ? renderCreatedProject()
                            : renderCreateProjectForm()}
                    </Box>
                </Col>
            </Row>
        </Wrapper>
    );
}

export default StartProject;
