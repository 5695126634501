import React from 'react';

import { useSelector } from 'react-redux';
import { Row, Col, Spinner } from '@commonsku/styles';
import LoginPopup from './Login';
import PortalHeroBannerTemplate from '../company-search/popups/PortalHeroBannerTemplate';
import { getIdentityUtils } from '../../utils';

function Home({ client, allShops }) {
    const identity = useSelector(s => s.identity);
    const portalData = useSelector(s => s.client_portal.entities.portal_data);
    const contactInStore = useSelector(s => s.client_portal.entities.client_contact);
    const { isAdmin, hasCapabilities } = getIdentityUtils(identity);
    let shouldBlur = true;
    let admin = false;

    if (identity && (
            isAdmin()
            || hasCapabilities(['MODIFY-CLIENT-REP'])
            || identity.user_id === client.sales_rep_id
        )
    )  {
        admin = true;
        shouldBlur = false;
    }
    if (contactInStore?.contact_id) shouldBlur = false;

    return (
        <>
            <LoginPopup client={client} identity={identity} portalData={portalData} isAdmin={admin} />
            {!portalData?.client_portal_id && <Spinner />}
            {portalData?.client_portal_id && <Row>
                <Col lg={12} sm={12}>
                    <PortalHeroBannerTemplate
                        showClientSpecific={portalData.show_client_specific}
                        bannerImages={portalData.images.split(',')}
                        shopIds={portalData.shops ? portalData.shops.split(',') : []}
                        allShops={allShops}
                        isEditable={false}
                        singleShop={+portalData.simple === 1}
                        themeColor={portalData.theme_color}
                        client={client}
                        blur={shouldBlur}
                    />
                </Col>
            </Row>}
        </>
    );
}

export default Home;
