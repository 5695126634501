import uniq from 'lodash/uniq';

export function getNotificationUserList(options, saleRepId, clientRepId) {
    if (!options) return [];

    const notifyUsers = [];
    for (const notifyOption of options.split(',')) {
        if (notifyOption === 'client_rep_role') {
            if (clientRepId) {
                notifyUsers.push(clientRepId);
            }
            continue;
        }

        if (notifyOption === 'sale_rep_role') {
            if (saleRepId) {
                notifyUsers.push(saleRepId);
            }
            continue;
        }
        notifyUsers.push(notifyOption);
    }

    return uniq(notifyUsers);
}