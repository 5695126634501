import ReactDOM from 'react-dom';
import { document } from '../../global';

export default function GalleryChatPortal({children}) {
    const root = document.getElementById('chat');
    return root ? ReactDOM.createPortal(
        children,
        root
    ) : null;
}
