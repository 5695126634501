import React, { useState, useRef, useEffect, useContext } from 'react';
import TextArea from 'react-textarea-autosize';
import WebsocketContext from '../../context/websocket-context';
import Avatar from 'react-avatar';
import { LabeledInput, Button } from '@commonsku/styles';
import { validateEmail } from '../../utils';
import { window, document } from '../../global';

const isAgentOnline = (agents) => {
    const currentTime = new Date();
    for (const agent of agents) {
        if (agent.is_online) return true;

        const onlineTime = new Date(agent.date_updated);
        const diff = currentTime - onlineTime;
        if (diff < 2 * 60 * 1000) {
            return true;
        } // seen 2min ago
    }

    return false;
};

const amIAgent = (identity, agents) => {
    for(const agent of agents) {
        if (agent.agent_id == identity) return true;
    }
    return false;
};

const getAgentName = (agents) => {
    const text = 'Chat Agent';
    if (agents.length === 0) return text;

    const agent = agents[0];
    return agent ? agent.user_first_name : text;
};

const getAgentImage = (agents, id = null) => {
    let image = undefined;
    if (agents.length === 0) return image;

    let agent = agents[0];
    if (id) {
        agent = agents.filter(a => a.agent_id === id || a.user_first_name === id)[0];
    }
    image = agent ? agent.user_image_paths?.small : image;
    if (image && image.startsWith('files')) {
        image = '/' + image;
    }
    return image;
};

const documentTitle = document.title;

export default function GalleryChat({ isShopProductPage=false }) {
    const {
        chats,
        users,
        handleAddChat,
        callUser: handleCall,
        handleAnswerCall,
        handleEndCall,
        callEnabled,
        agents,
        tenantOptions,
        connected,
        connecting,
        connectionInited,
        identityId,
        defaultColor,
        supportSession,
        supportSessionData,
        supportSessionLoading,
        supportSessionIsAgent,
        supportAgentOnline,
        supportSessionCreated,
        setSupportSessionCreated,
        supportUserName,
        setSupportUserName,
        supportUserEmail,
        setSupportUserEmail,
        newMessageCount,
        setNewMessageCount,
    } = useContext(WebsocketContext);
    const [chatMessage, setChatMessage] = useState('');
    const [canChat, setCanChat] = useState(false);
    const [minimize, setMinimize] = useState(true);
    const ulElement = useRef(null);
    const [requested, setRequested] = useState(false);
    const [nameValid, setNameValid] = useState(true);
    const [emailValid, setEmailValid] = useState(true);

    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session');
    const autoOpenAgentChat = sessionId ? true : false;

    useEffect(() => {
        window.setTimeout(() => {
            const ele = ulElement.current;
            if (!ele) return false;

            ele.scrollTop = ele.scrollHeight - ele.clientHeight;
        }, 200);

    }, [chats]);

    useEffect(() => {
        setCanChat(isAgentOnline(agents));
        if (amIAgent(identityId, agents)) {
            // Auto start chat session for agent
            if (autoOpenAgentChat) {
                setMinimize(!autoOpenAgentChat);
            }
            setSupportSessionCreated(true);
        }
    }, [agents, autoOpenAgentChat, identityId, setSupportSessionCreated]);

    useEffect(() => {
        const ele = document.getElementById('favicon');
        if (ele) {
            if (newMessageCount === 0) {
                document.title = documentTitle;
                ele.href="/images/favicons/favicon.ico";
            } else {
                document.title = `(${newMessageCount}) ${documentTitle}`;
                ele.href="/images/favicons/red-dot-favicon.ico";
            }
        }
    }, [newMessageCount]);

    if ((!connected && !connectionInited) || !tenantOptions || !tenantOptions.chat_welcome_message) {
        return null;
    }

    let userList = null;
    if (callEnabled) {
        userList = (
            <>
                <h3>Online Users</h3>
                <ul>
                    {
                        users.map((user, index) => {
                            if (user.lastUpdate < Math.floor(Date.now() / 1000) - 20) return null; // inactive/disconected user
                            return (
                                <li className='chatMessage' key={user.userId}>
                                    {user.userId.split('-').pop()} {' '}
                                    {user.status === 'connected' && <button className="button" onClick={(e) => { handleCall(user.userId); }}>Call</button>}
                                    {user.status === 'incoming' && <button className="button" onClick={(e) => { handleAnswerCall(user.userId); }}>Answer Call</button>}
                                    {user.status === 'dialing' && <button className="button" disabled>Dialing ... </button>}
                                    {user.status === 'calling' && <button className="button" onClick={(e) => { handleEndCall(user.userId); }}>End Call</button>}
                                </li>
                            );
                        })
                    }
                </ul>
            </>
        );
    }

    const containerClass = [
        'sticky-chat',
        (minimize ? 'minimize' : ''),
        (canChat ? 'canChat' : ''),
        isShopProductPage ? 'is-shop-product-page' : '',
    ].filter(v => Boolean(v)).join(' ');
    const onlineMessage = tenantOptions?.chat_welcome_message ?? 'Welcome and we are online to chat with you - default';
    const offlineMessage = tenantOptions?.chat_offline_message ?? 'Welcome, however, all our agents are offline right now - default';
    const helloMessage = canChat ? onlineMessage : offlineMessage;
    const showHelloMessage = !amIAgent(identityId, agents);
    const welcomeMessage = (
        <li className={`chatMessage from-others`} key='welcome-message'>
            <div className='chat-message-content' style={{maxWidth: '100%'}}>
                <div className='chat-message-wrapper'>
                    <Avatar src={tenantOptions.avatar} size={40} className='chat-avatar' round={true} />
                    <div className='chat-message-main' style={{backgroundColor: defaultColor, '--beforecolor': defaultColor}}>{helloMessage}</div>
                </div>
            </div>
        </li>
    );

    // Set up session status message
    // console.log('support session', supportSessionIsAgent, supportSessionLoading, supportSession);
    let supportSessionMessage = 'We are creating a session for you, please wait';
    if (supportSessionIsAgent) {
        if (supportSessionLoading) {
            supportSessionMessage = 'Accepting session for you, please wait';
        } else {
            if (!supportSession) {
                supportSessionMessage = 'Another agent is already in session.';

                if (!autoOpenAgentChat) supportSessionMessage = 'You are an agent. Chat support session is not available.';
            } else {
                // console.log(supportSessionData, 'supportSessionData');
                supportSessionMessage = `You are in support session with ${supportSessionData.guest_name} (${supportSessionData.guest_email}) now.`;
            }
        }
    } else {
        if (supportSession) {
            supportSessionMessage = 'Support session has been successfully created.';
        }
    }
    supportSessionMessage = <div className='chat-info'>{supportSessionMessage}</div>;

    // Set up connected to an agent message
    let supportAgentOnlineMessage = <div>We are connecting you to an agent, please wait...<div><img src="/images/gears.gif" /></div></div>;
    if (supportSessionIsAgent) {
        supportAgentOnlineMessage = '';
    } else {
        if (supportAgentOnline) {
            supportAgentOnlineMessage = 'You are connected to an agent (' + supportAgentOnline + '). Chat away!';
        }
    }
    supportAgentOnlineMessage = <div className='chat-info'>{supportAgentOnlineMessage}</div>;

    const canTypeChat = (supportSessionIsAgent && supportSession) || (!supportSessionIsAgent && supportAgentOnline);

    // console.log(supportAgentOnline, supportSessionIsAgent);

    const shouldAskForName = !supportSessionIsAgent && !supportSession && !requested;
    const nameEmailForm = (
        <div className='supportForm' style={{padding: '1em'}}>
            <LabeledInput label="Name" value={supportUserName} onChange={e => setSupportUserName(e.target.value)} />
            {!nameValid && <div className="error" style={{color: 'red', marginBottom: '20px'}}>Invalid Name</div>}
            <LabeledInput label="Email" value={supportUserEmail} onChange={e => {
                setSupportUserEmail(e.target.value);
            }} />
            {!emailValid && <div className="error" style={{color: 'red', marginBottom: '20px'}}>Invalid Email</div>}
            <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <Button style={{backgroundColor: defaultColor, borderColor: defaultColor}} onClick={() => {
                if (supportUserName.length === 0) {
                    setNameValid(false);
                    return;
                } else {
                    setNameValid(true);
                }
                if (supportUserEmail.length === 0 || !validateEmail(supportUserEmail)) {
                    setEmailValid(false);
                    return;
                } else {
                    setEmailValid(true);
                }
                if (supportUserName.length > 0 && supportUserEmail.length > 0 && !supportSessionCreated) {
                    setSupportSessionCreated(true);
                    setRequested(true);
                }
            }}>Request Chat</Button></div>
        </div>
    );

    let chatWithName = 'US';
    if (supportSessionIsAgent && supportSessionData) {
        chatWithName = supportSessionData.guest_name;
    }
    if (!supportSessionIsAgent && supportAgentOnline) {
        chatWithName = supportAgentOnline;
    }

    const chatBlock = (
        <div className='chat-block-container'>
            {!minimize && <button className="close-button" onClick={(e) => { setMinimize(true); }}>&times;</button>}
            <div className="chat-title" style={{backgroundColor: defaultColor}}>
                CHAT WITH {chatWithName}
            </div>
            <div className="chat-block">
                <div>
                    {userList}
                    <ul className="chat-messages" ref={ulElement}>
                        {showHelloMessage && welcomeMessage}
                        {shouldAskForName && nameEmailForm}
                        {!shouldAskForName && supportSessionMessage}
                        {!shouldAskForName && supportAgentOnlineMessage}
                        {
                            chats.map(({ message: chat, time, userId, userName }, index) => {
                                const fromMe = identityId === userId;
                                const styleClassName = fromMe ? 'from-me' : 'from-others';
                                const backgroundColorStyle = !fromMe ? {backgroundColor: defaultColor, '--beforecolor': defaultColor} : {};
                                return (
                                    <li className={`chatMessage ${styleClassName}`} key={index}>
                                        <div className='chat-message-content'>
                                            <div className='chat-message-wrapper'>
                                                { !fromMe && <Avatar src={getAgentImage(agents, userId)} size={40} name={userName !== 'Anonymous' ? userName : userName + ': ' + userId} className='chat-avatar' round={true} />}
                                                <div className='chat-message-main' style={backgroundColorStyle}>{chat} <span className="chat-time">{time.toLocaleTimeString()}</span></div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })
                        }
                        {!connected && <p>Connection lost... Trying to reconnect...</p>}
                    </ul>
                    <div style={{ position: 'absolute', left: 0, bottom: 10, display: 'flex', width: '100%', padding: '0 1em' }}>
                        <TextArea
                            disabled={!canTypeChat || !connected}
                            style={{ flexGrow: '1' }}
                            rows={1}
                            value={chatMessage}
                            placeholder={canTypeChat ? "Type message and press enter" : 'Please wait, setting up session'}
                            onChange={(e) => setChatMessage(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleAddChat(chatMessage); setChatMessage('');
                                } else {
                                    setNewMessageCount(0);
                                }
                            }}>

                        </TextArea>
                    </div>
                </div>
            </div>
        </div>
    );
    const agentName = supportAgentOnline ? supportAgentOnline : getAgentName(agents);
    const agentStatusTitle = canChat ? `${agentName} is online` : `${agentName} is offline`;
    const agentStatusStyle = canChat ? 'canChat' : '';
    const agentBlock = <Avatar
        src={getAgentImage(agents, supportAgentOnline)}
        name={agentName}
        title={agentStatusTitle}
        size={80} round={true}
        className={`sticky-agent-block ${agentStatusStyle} ${isShopProductPage ? 'is-shop-product-page' : ''}`}
        onClick={(e) => {
            setMinimize(!minimize);
        }}
        style={canChat ? { zIndex: 9 } : {}}
    />;
    return (
        <>
            <div className={containerClass} title={agentStatusTitle}>
                {!minimize && chatBlock}
            </div>
            {agentBlock}
        </>
    );
}
