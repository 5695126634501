import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getIdentityUtils } from '../utils';

export default function useClientContactLogin(client) {
    const navigate = useNavigate();

    const contactInStore = useSelector(s => s.client_portal.entities.client_contact);
    const loaded = useSelector(s => s.client_portal.entities.client_contact_loaded);
    const identity = useSelector(s => s.identity);
    const dispatch = useDispatch();
    const { isAdmin, hasCapabilities } = getIdentityUtils(identity);

    React.useEffect(() => {
        if (!loaded) return;

        // Client rep
        if (identity && (isAdmin() || hasCapabilities(['MODIFY-CLIENT-REP']) || identity.user_id === client.sales_rep_id)) return;

        // Already login
        if (contactInStore?.contact_id && contactInStore?.company_id === client.client_id) return;

        navigate(`/client/${client.client_id}/portal/`);

    }, [client.client_id, client.sales_rep_id, contactInStore, dispatch, hasCapabilities, identity, isAdmin, loaded, navigate]);
    return [];
}
