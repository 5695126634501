import React from 'react';
import GalleryChatPortal from './GalleryChatPortal';
import GalleryChat from './GalleryChat';

export default function ShopChat({ isShopProductPage=false }) {
    return (
        <GalleryChatPortal>
          <GalleryChat isShopProductPage={isShopProductPage} />
        </GalleryChatPortal>
    );
}
