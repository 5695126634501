import React from 'react';
import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';
import { Col, Row, Wrapper, getThemeColor } from '@commonsku/styles';
import { getAvatarSrc } from '../utils';

export const NavLink = styled(Link)`
  &&& {
    color: ${p => getThemeColor(p, 'primary')};
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1rem 1rem;
    height: 100%;
    cursor: pointer;
    &.active {
      border-color: ${p => getThemeColor(p, 'primary')};
      border-bottom: 2px solid;
    }
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'skufont-demibold',sans-serif;
  }

  @media only screen and (min-width: 0px) {
    padding: 1rem 0.55rem !important;
  }
  @media only screen and (max-width: 360px) {
    &.active {
      padding: 0rem 0.2rem !important;
    }
  }
  @media only screen and (min-width: 640px) {
    padding: 1rem 1rem !important;
    &.active {
      padding: 1rem 1rem !important;
    }
  }
`;

function Topbar({ title, base_path, avatar={} }) {
  return (
    <>
      <Row bg="#fff" padded style={{ textAlign: 'center', }} className="top-nav">
        <Col padded xs sm={6} md={6} lg={6} className="nav-left">
          <Row>
            <Col xs={5} style={{ maxWidth: 100, width: 100, height: 100, }}>
              {avatar && (avatar.original || avatar.medium || avatar.small)
                ? <img className="left-img" src={`${getAvatarSrc(avatar)}`} alt="company_logo" style={{ height: '100%', width: '100%', }} />
                : <img className="left-img" src="/images/favicons/favicon-96x96.png" alt="company_logo" style={{ height: '100%', width: '100%', }} />}
            </Col>
            <Col padded xs={7} style={{ verticalAlign: 'middle', textAlign: 'left', }}>{title}</Col>
          </Row>
        </Col>
        <Col padded xs sm={6} md={6} lg={6} style={{
          justifyContent: 'flex-end',
          zIndex: 10,
        }} className="nav-right">
          <Wrapper flex float="right" style={{ alignItems: "center", }} className="nav-items-container">
            <NavLink end to={`${base_path}/`} className='nav-link-overwrite'>Home</NavLink>
            <NavLink end to={`${base_path}/start-project`} className='nav-link-overwrite'>Start A Project</NavLink>
            <NavLink end to={`${base_path}/projects`} className='nav-link-overwrite'>Projects</NavLink>
          </Wrapper>
        </Col>
      </Row>
    </>
  );
}

export default Topbar;
