import React, { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { oauth } from '../utils';
import ErrorBoundary from '../components/ErrorBoundary';
import Modal from '../components/Modal';
import Overlay from '../components/Overlay';
import Title from '../components/client-portal/Title';
import Layout from '../components/client-portal/Layout/Layout';
import PortalMenu from '../components/client-portal/Menu';
import PortalConfiguration from '../components/client-portal/Configuration';
import PortalStartProject from '../components/client-portal/StartProject';
import PortalProjects from '../components/client-portal/Projects';
import PortalReorderOrder from '../components/client-portal/ReorderOrder';
import PortalLogin from '../components/client-portal/Login';
import PortalHome from '../components/client-portal/Home';
import { themeOptions, getAvatarSrc } from '../components/client-portal/utils';
import _ from 'lodash';
import { getWithExpiry } from '../local-storage-util';

import { WebsocketContextProvider } from '../context/websocket-context';
import ShopChat from '../components/chat/ShopChat';
import { getNotificationUserList } from '../helpers/chat';
import { getClientById } from '../selectors';
import { setClientContact, setClientContactLoaded, setPortalDataStore } from '../store/configureClientPortalStore';
import { window } from '../global';

const DEFAULT_COLOR = '#5ca3b6';
function ClientPortalApp({ identity, popups, base_path, client, shops, params, ...props }) {
  const dispatch = useDispatch();
  const company_avatar = client && client.company_avatar ? client.company_avatar : {};

  const contactInStore = useSelector(s => s.client_portal.entities.client_contact);
  const [portalData, setPortalData] = useState(null);

  const getClientPortal = useCallback(async (clientId) => {
      const portalData = await oauth('GET', 'client-portal-admin/get-client-portal', { client_id: clientId });
      setPortalData(portalData.json.portal);
      dispatch(setPortalDataStore(portalData.json.portal));
  }, [dispatch]);

  useEffect(() => {
      getClientPortal(client.client_id);
  }, [client.client_id, getClientPortal]);

  useEffect(() => {
    // remove resku from body and html tags
    document.getElementsByTagName('body')[0].className = '';
    document.getElementsByTagName('html')[0].className = '';
  });

  useEffect(() => {
    if (_.isEmpty(contactInStore)) {
        const localStorageContact = getWithExpiry('client_contact');
        if (localStorageContact && localStorageContact.company_id === client.client_id) {
          dispatch(setClientContact(localStorageContact));
        } else {
          dispatch(setClientContactLoaded(true));
        }
    }
}, [client.client_id, contactInStore, dispatch]);

  function getPanelLayoutProps(panel) {
    switch (panel) {
      case 'reorder-order':
        return {
          style: {
            background: themeOptions.colors.primary0,
          }
        };
      case 'start-project':
        return {
          style: {
            background: themeOptions.colors.primary0,
          },
          body_bg: themeOptions.colors.primary0,
        };
      case 'home':
        return {
          styles: {},
        };
      default:
        return {};
    }
  }

  function renderPanel(panel) {
    const panelProps = { client: client, };
    switch (panel) {
      case 'config':
        return <PortalConfiguration {...panelProps} />;
      case 'start-project':
        return <PortalStartProject {...panelProps} />;
      case 'projects':
        return <PortalProjects {...panelProps} base_path={base_path} />;
      case 'reorder-order':
        return <PortalReorderOrder {...panelProps} client_id={client.client_id} order_id={params.order_id} />;
      case 'login':
        return <PortalLogin {...panelProps} />;
      case 'home':
        return <PortalHome {...panelProps} allShops={shops} />;
      default:
        return null;
    }
  }

  function renderModal(modal) {
    return null;
  }

  const menu = <PortalMenu client={client} identity={identity} />;
  const title = <Title client_name={client.client_name} tenant_name={client.tenant_name} />;
  const chatChannel = window.location.href.split('/').slice(0, 5).join('/');
  let tenantOptions = {...portalData?.chat_bot};
  const notifyUser = getNotificationUserList(tenantOptions?.chat_notification_user, client.sales_rep_id, client.sales_rep_id);
  if (tenantOptions) {
    tenantOptions.avatar = getAvatarSrc(company_avatar) ?? '/images/favicons/favicon-96x96.png';
  }

  if (props.modal) {
    return (
      <div>
        <Modal modal={renderModal(props.modal)} client={client} popups={popups} />
        <Overlay popups={popups} />
      </div>
    );
  }

  return (
    <ErrorBoundary>
      <Layout popups={popups} menu={menu} title={title} avatar={company_avatar} base_path={base_path} {...getPanelLayoutProps(props.panel)}>
      <WebsocketContextProvider
          identity={identity}
          notifyUser={notifyUser}
          canVoiceCall={false}
          channel={chatChannel}
          tenantOptions={tenantOptions}
          tenantId={client.tenant_id}
          defaultColor={DEFAULT_COLOR}
          getBots={false}
        >
        {renderPanel(props.panel)}
        <ShopChat />
        </WebsocketContextProvider>
      </Layout>
      <Overlay popups={popups} />
    </ErrorBoundary>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
    state: state,
    popups: state.display.popups,
    client: getClientById(state, ownProps.params),
    shops: state.entities.shops || []
  };
};
const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ClientPortalApp);
