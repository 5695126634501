import React from 'react';
import { Routes, Route, useLocation, useParams, BrowserRouter } from 'react-router-dom';

import ClientPortalApp from '../../containers/ClientPortalApp';
import { reducers } from '../../store/configureClientPortalStore';
import withReducers from '../../store/withReducers';
import NoMatch from '../NoMatch';
import ErrorBoundary from '../ErrorBoundary';

export const URL_PREFIX = '/client/:client_id/portal';

const AppRoute = React.memo(({ panel }) => {
  const params = useParams();
  const location = useLocation();
  const base_path = URL_PREFIX.replace(':client_id', params?.client_id || '');

  if (panel) {
    return <ClientPortalApp panel={panel} base_path={base_path} params={params} location={location} />;
  }

  return <NoMatch location={location} />;
});

const ClientPortalRouter = ({ location }) => (
  <BrowserRouter basename={location}>
    <Routes>
      <Route
        path={URL_PREFIX}
        element={<AppRoute panel="home" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={`${URL_PREFIX}/projects`}
        element={<AppRoute panel="projects" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={`${URL_PREFIX}/start-project`}
        element={<AppRoute panel="start-project" />}
        ErrorBoundary={ErrorBoundary}
      />
      <Route
        path={"*"}
        element={<AppRoute noMatch />}
        ErrorBoundary={ErrorBoundary}
      />
    </Routes>
  </BrowserRouter>
);

export default withReducers(ClientPortalRouter, reducers, true);
