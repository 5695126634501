import React from 'react';
import { H1, Text } from '@commonsku/styles';

function Title({ client_name, tenant_name }) {
    return (
        <Text style={{fontWeight: 'bold'}} className="title">
            <H1 style={{display: 'block', margin: 0}}>{client_name}</H1>
            <Text as="span" style={{display: 'block', fontSize: 18}}>{tenant_name}</Text>
        </Text>
    );
}

export default Title;
