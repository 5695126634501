import { Col, Row, LabeledInput, Button, Popup, Input } from '@commonsku/styles';
import React, { useState, useCallback, useEffect, useRef } from 'react';

import { oauth } from '../../utils';
import { setClientContact } from '../../store/configureClientPortalStore';
import { useDispatch, useSelector } from 'react-redux';
import { setWithExpiry } from '../../local-storage-util';

const DEFAULT_COLOR = '#02c0da';

function LoginPopup({ client, identity, portalData, isAdmin}) {
    const template_color = portalData?.theme_color ? portalData.theme_color : DEFAULT_COLOR;

    const dispatch = useDispatch();
    const contactInStore = useSelector(s => s.client_portal.entities.client_contact);
    const [email, setEmail] = useState(contactInStore?.email ?? '');
    const [emailInvalid, setEmailInvalid] = useState(false);
    const [pin1, setPin1] = useState('');
    const [pin2, setPin2] = useState('');
    const [pin3, setPin3] = useState('');
    const [pin4, setPin4] = useState('');
    const [pinInvalid, setPinInvalid] = useState(false);
    const [tries, setTries] = useState(0);
    const [pinTries, setPinTries] = useState(0);
    const [resent, setResent] = useState(null);
    const [emailSending, setEmailSending] = useState(false);

    const pin1El = useRef(null);
    const pin2El = useRef(null);
    const pin3El = useRef(null);
    const pin4El = useRef(null);

    const resetLoginEmail = useCallback(() => {
        dispatch(setClientContact({
            contact_email: null
        }));
    }, [dispatch]);

    const createNewPortalLogin = useCallback(async () => {
        if (pinTries > 2) return;

        try {
            setEmailSending(true);
            setPinTries(old => old + 1);
            await oauth('POST', 'client-portal-login', { client_id: client.client_id, email: email });
            // Set email in client contact identity
            dispatch(setClientContact({
                contact_email: email
            }));
            setEmailInvalid(false);
            setEmailSending(false);
        } catch (e) {
            setEmailInvalid(true);
            setEmailSending(false);
        }
    }, [client.client_id, dispatch, email, pinTries]);

    const checkNewPortalLogin = useCallback(async (pin) => {
        if (tries > 2) return;

        let contact = {};
        try {
            setTries(old => old + 1);
            contact = await oauth('POST', 'client-portal-login', { action: 'check-pin', client_id: client.client_id, email: email, pin: pin });
            dispatch(setClientContact(contact.json.contact));
            setWithExpiry('client_contact', contact.json.contact, 1000 * 3600); // Save to local storage for 1hour
            setPinInvalid(false);
        } catch (e) {
            setPinInvalid(true);
        }
    }, [client.client_id, dispatch, email, tries]);

    useEffect(() => {
        if (contactInStore?.contact_email && !contactInStore?.contact_id) {
            pin1El.current.focus();
        }
    }, [contactInStore, dispatch]);

    if (isAdmin) return null; // client rep don't need to login
    if (contactInStore?.contact_id) return null;

    const loginFlow = (
        <>
            {!contactInStore?.contact_email && <Row>
                <Col style={{marginTop: '1.5em'}}>
                    <LabeledInput error={emailInvalid} name="name" value={email} onChange={e => setEmail(e.target.value)} placeholder="Your Email" />
                    {emailInvalid && <p className="error" style={{marginBottom: '1em'}}>No such email found for this client</p>}
                    {!emailSending && <Button style={{background: template_color, borderColor: template_color}} onClick={() => { createNewPortalLogin(); }}>Continue</Button>}
                    {emailSending && <p>Sending email, please wait...</p>}

                    <p style={{marginTop: '1em'}}>If you have questions, please contact{' '}
                        <a style={{color: template_color, textDecoration: 'none'}} href="javascript:void(0)" mailto={client.sales_rep.user_email}>{client.sales_rep.user_email}</a>
                    </p>
                </Col>
            </Row>}

            {contactInStore?.contact_email && !contactInStore?.contact_id && <Row>
                <Col>
                    <p>We sent a 4-digit pin to <b>{contactInStore?.contact_email}</b>{' '}
                        <a style={{color: template_color, textDecoration: 'none'}} href="javascript:void(0)" onClick={e => resetLoginEmail()}>not your email?</a>
                    </p>
                    <p>Please enter it here to view your past projects:</p>
                    <div className="digit-pins" style={{ display: "flex", alignItems: "top", flexDirection: "row" }}>
                        <Input disabled={tries > 3} error={pinInvalid} ref={pin1El} name="pin1" value={pin1}
                            onChange={e => {
                                setPin1(e.target.value.substring(0, 1));
                                if (!pin2) pin2El.current.focus();
                            }}
                            onPaste={e => {
                                const clipboardData = e.clipboardData || window.clipboardData;
                                const pastedData = clipboardData.getData('Text');
                                if (pastedData.length === 4) {
                                    setPin1(pastedData.substring(0, 1));
                                    setPin2(pastedData.substring(1, 2));
                                    setPin3(pastedData.substring(2, 3));
                                    setPin4(pastedData.substring(3, 4));
                                    checkNewPortalLogin(pastedData);
                                }
                            }}
                        />
                        <Input disabled={tries > 3} error={pinInvalid} ref={pin2El} name="pin2" value={pin2}
                            onChange={e => {
                                setPin2(e.target.value.substring(0, 1));
                                if (!pin3) pin3El.current.focus();
                            }}
                        />
                        <Input disabled={tries > 3} error={pinInvalid} ref={pin3El} name="pin3" value={pin3}
                            onChange={e => {
                                setPin3(e.target.value.substring(0, 1));
                                if (!pin4) pin4El.current.focus();
                            }}
                        />
                        <Input disabled={tries > 3} error={pinInvalid} ref={pin4El} name="pin4" value={pin4} onChange={e => {
                            setPin4(e.target.value.substring(0, 1));
                            checkNewPortalLogin(`${pin1}${pin2}${pin3}${e.target.value.substring(0, 1)}`);
                        }} />
                    </div>
                    {pinInvalid && <p className="error" style={{marginBottom: '1em'}}>Pin is invalid</p>}
                    {tries > 3 && <p className="error" style={{marginBottom: '1em'}}>Too many tries</p>}
                    {pinTries <= 2 && (
                        <p>
                            Didn't get the pin? Check your Spam folder.
                        </p>
                    )}
                    {resent !== null && <p>{resent}</p>}
                    {false && <Button disabled={tries > 3} onClick={() => { checkNewPortalLogin(); }}>Check Pin</Button>}
                </Col>
            </Row>}

            {contactInStore?.contact_email && contactInStore?.contact_id && <Row>
                <Col>
                    Welcome {contactInStore?.contact_first_name}
                </Col>
            </Row>}
        </>
    );

    const firstStep = !contactInStore?.contact_email;

    return (
        <Popup style={{ height: 'auto', maxWidth: '960px' }} width='calc(100vw - 100px)' header={<span></span>}>
            <Row>
                {firstStep && <Col sm={12} lg={8}>
                    <h3>Welcome to the client portal!</h3>
                    <p>Please provide your email to continue</p>
                </Col>}
                <Col sm={12} lg={firstStep ? 4 : 12}>
                    {loginFlow}
                </Col>
            </Row>
        </Popup>
    );
}

export default LoginPopup;
